import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IMapsPrepareForOfflineUseProps} from "./MapsPrepareForOfflineUse";

const MapsPrepareForOfflineUseLazy = lazy(() => import("./MapsPrepareForOfflineUse"));

export const MapsPrepareForOfflineUse = connect((props: IMapsPrepareForOfflineUseProps) => (
  <LazyLoadComponent>
    <MapsPrepareForOfflineUseLazy {...props}/>
  </LazyLoadComponent>
));
