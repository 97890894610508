import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ICreateCompanyPageProps} from "./CreateCompanyPage";

const CreateCompanyPageLazy = lazy(() => import("./CreateCompanyPage"));

export const CreateCompanyPage = connect((props: ICreateCompanyPageProps) => (
  <LazyLoadComponent>
    <CreateCompanyPageLazy {...props}/>
  </LazyLoadComponent>
));
