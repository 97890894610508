import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {CreateCompanyIcon} from "../assets/icons";

export const routeCreateCompanyPagePaths: IAppRoute = {
  menuId: 'create-company',
  title: 'Create Company',
  subtitle: 'Create a new company in the system',
  description: 'Create a new company to be accessed by the users',
  icon: <CreateCompanyIcon/>,
  routePath: '/create-company',
  getRoutePath: () => routeCreateCompanyPagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  render: () => null,
};
