import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IUsersInvitationsListPageProps} from "./UsersInvitationsListPage";

const UsersInvitationsListPageLazy = lazy(() => import("./UsersInvitationsListPage"));

export const UsersInvitationsListPage = connect((props: IUsersInvitationsListPageProps) => (
  <LazyLoadComponent>
    <UsersInvitationsListPageLazy {...props}/>
  </LazyLoadComponent>
));
