import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {UsersInvitationsIcon} from "../assets/icons";

export const routeUsersInvitationsListPaths: IAppRoute = {
  menuId: 'usr-invitations',
  title: 'Users Invitations',
  subtitle: 'List and manage the users invitations',
  description: 'Manipulate users invitations, create, delete or resent invitations',
  icon: <UsersInvitationsIcon/>,
  routePath: '/users-invitations',
  getRoutePath: () => routeUsersInvitationsListPaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(EUserAuthenticationRights),
  render: () => null,
};
