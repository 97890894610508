import {IAppMenu} from "./IAppMenu";

import {convertRouteToAppMenu} from "./IAppMenu";

// Mercury
import {routeDeviceManagementMainPage} from "../../device-management/routes/routeDeviceManagementMainPage";
import {routeDeviceManagementMapPaths} from "../../device-management/routes/routeDeviceManagementMap.paths";
import {routeDeviceManagementVirtualTreePaths} from "../../device-management/routes/routeDeviceManagementVirtualTree.paths";

// CIMs
import {routeWorkZonesList} from "../../workzones-management/routes/routeWorkZonesList";
import {routeSaveOfflineMaps} from "../../workzones-management/routes/routeSaveOfflineMaps";
import {routeWorkzoneOfflinePrepare} from "../../workzones-management/routes/routeWorkzoneOfflinePrepare";

// Alerts management
import {routeAlertMgtmMainPage} from "../../alerts-management/routes/routeAlertMgtmMainPage";
import {routeAlertMgtmListPage} from "../../alerts-management/routes/routeAlertMgtmListPage";

// Lane planning
import {routeLanePlanningMainPage} from "../../lane-planning/routes/routeLanePlanningMainPage";
import {routeLanePlanningPage} from "../../lane-planning/routes/routeLanePlanningPage";

// Traffic studies
import {routeTrafficStudyList} from "../../traffic-studies/routes/routeTrafficStudyList";

// Administrator
import {routeUserManagementMainPagePaths} from "../../user-management/routes/routeUserManagementMainPage.paths";
import {routeListUsersPage} from "../../user-management/routes/routeListUsersPage";
import {routeUsersInvitationsList} from "../../users-invitations/routes/routeUsersInvitationsList";

// App options
import {routeCreateCompanyPage} from "../../user-authnentication/routes/routeCreateCompanyPage";
import {routeAppSettingsPage} from "../routes/routeAppSettingsPage";

// About page
import {routeAppAboutPage} from "../routes/routeAppAboutPage";
import {routeCIMsMainPage} from "../../workzones-management/routes/routeCIMsMainPage";
import {routeTrafficStudyMainPage} from "../../traffic-studies/routes/routeTrafficStudyMainPage";


export const appMenusTop: (IAppMenu | "DIVIDER")[] = [
  convertRouteToAppMenu(
    routeDeviceManagementMainPage,
    {
      children: [
        convertRouteToAppMenu(routeDeviceManagementMapPaths),
        convertRouteToAppMenu(routeDeviceManagementVirtualTreePaths),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeAlertMgtmMainPage,
    {
      children: [
        convertRouteToAppMenu(routeAlertMgtmListPage),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeCIMsMainPage,
    {
      children: [
        convertRouteToAppMenu(routeWorkZonesList),
        convertRouteToAppMenu(routeWorkzoneOfflinePrepare),
        convertRouteToAppMenu(routeSaveOfflineMaps),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeLanePlanningMainPage,
    {
      children: [
        convertRouteToAppMenu(routeLanePlanningPage),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeTrafficStudyMainPage,
    {
      children: [
        convertRouteToAppMenu(routeTrafficStudyList),
      ],
    },
  ),
  convertRouteToAppMenu(
    routeUserManagementMainPagePaths,
    {
      children: [
        convertRouteToAppMenu(routeListUsersPage),
        convertRouteToAppMenu(routeUsersInvitationsList),
        convertRouteToAppMenu(routeCreateCompanyPage),
      ],
    },
  ),
  convertRouteToAppMenu(routeAppAboutPage),
];

export const appMenusBottom: IAppMenu[] = [
  {
    menuId: routeAppSettingsPage.menuId,
    navigateTo: routeAppSettingsPage.routePath,
    userHasAllRights: routeAppSettingsPage.userHasAllRights,
    userHasAnyOfRights: routeAppSettingsPage.userHasAnyOfRights,
    title: routeAppSettingsPage.title,
    subtitle: routeAppSettingsPage.subtitle,
    icon: routeAppSettingsPage.icon,
  },
];
