import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAppMainPageProps} from "./AppMainPage";

const HomePageLazy = lazy(() => import("./AppMainPage"));

export const AppMainPage = connect((props: IAppMainPageProps) => (
  <LazyLoadComponent>
    <HomePageLazy {...props}/>
  </LazyLoadComponent>
));
