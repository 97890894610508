import {ELanePanningRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {LanePlanningManageIcon} from "../assets/icons";
import {LanePlanningPage} from "../pages/LanePlanningPage";

export const routeLanePlanningPage: IAppRoute = {
  menuId: '##line-planning-manage',
  icon: <LanePlanningManageIcon/>,
  title: 'Manage Lane Planning',
  subtitle: 'Manage and export lane planning',
  description: 'Create lanes planning on the map and export them for other applications',
  routePath: '/lane-planning/edit',
  getRoutePath: () => routeLanePlanningPage.routePath,
  userHasAnyOfRights: Object.values(ELanePanningRights),
  userHasAllRights: [],
  render: () => <LanePlanningPage/>,
};
