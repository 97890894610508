import {IAppRoute} from "../config/IAppRoute";

import {routeAppVersion} from "./routeAppVersion";
import {routeAppAboutPage} from "./routeAppAboutPage";
import {routeAppMainPage} from "./routeAppMainPage";
import {routeAppSettingsPage} from "./routeAppSettingsPage";
import {routeUserManagementMainPage} from "../../user-management/routes/routeUserManagementMainPage";
import {routeBroadcasterConnectionTestPage} from "./routeBroadcasterConnectionTestPage";

export const routesAppCore: IAppRoute[] = [
  routeAppAboutPage,
  routeAppVersion,
  routeAppMainPage,
  routeAppSettingsPage,
  routeUserManagementMainPage,
  routeBroadcasterConnectionTestPage,
];
