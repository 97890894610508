import {EDeviceManagementRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {DeviceManagementTreeIcon} from "../assets/icons";

export const routeDeviceManagementTreePaths: IAppRoute = {
  menuId: 'device-management-tree',
  icon: <DeviceManagementTreeIcon/>,
  title: 'Devices list',
  subtitle: 'Old version deprecated',
  description: 'Manage devices in hierarchical tree',
  routePath: '/device-management/tree--v1',
  getRoutePath: () => routeDeviceManagementTreePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
    EDeviceManagementRights.CONFIG_DEVICES,
  ],
  render: () => null,
};
