import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IDeviceMangerPingPongTestPageProps} from "./DeviceMangerPingPongTestPage";

const DeviceMangerPingPongTestPageLazy = lazy(() => import("./DeviceMangerPingPongTestPage"));

export const DeviceMangerPingPongTestPage = connect((props: IDeviceMangerPingPongTestPageProps) => (
  <LazyLoadComponent>
    <DeviceMangerPingPongTestPageLazy {...props}/>
  </LazyLoadComponent>
));
