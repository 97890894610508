import { hashMd5 } from "utils-library/dist/hash-md5";
import { EUserAuthenticationRights, EAuthStrategy, } from "../../../user-authentication/client/for-web-app";
export const testCreateUser = (user = {}, index_) => {
    const index = (index_ || 0) + 42323;
    const applyUser = Object.assign({ title: 'Mr.', firstName: 'John', lastName: 'Smith' }, user);
    const timestamp = 1610521000000
        + applyUser.lastName.split('').reduce((acc, letter) => acc + letter.charCodeAt(0), 0);
    const id = `ID-${index}-TEST-USER`;
    return Object.assign(Object.assign({ id, idHash: hashMd5(id), authUserId: `${EAuthStrategy.OAUTH_GOOGLE}/0245nj2to240t2tj23` + index, createdAt: timestamp + 10, updatedAt: timestamp + 20, deletedAt: 0, rights: applyUser.rights || [EUserAuthenticationRights.ACTIVE] }, applyUser), { displayName: `${applyUser.firstName[0]}.${applyUser.lastName}`, email: user.email || `${applyUser.firstName}-${applyUser.lastName[0]}@example.com`.toLowerCase(), createdBy: 'test-system', updatedBy: 'test-system', deletedBy: '', archivedAt: 0, archivedBy: '' });
};
