import {ELanePanningRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {LanePlanningHome} from "../pages/LanePlanningMainPage";
import {LanePlanningSectionIcon} from "../assets/icons";


export const routeLanePlanningMainPage: IAppRoute = {
  menuId: '##line-planning-home',
  icon: <LanePlanningSectionIcon/>,
  title: 'Lane Planning',
  subtitle: 'Manipulate lane plannings',
  description: 'Create lanes planning on the map and export them for other applications',
  routePath: '/lane-planning',
  getRoutePath: () => routeLanePlanningMainPage.routePath,
  userHasAnyOfRights: Object.values(ELanePanningRights),
  userHasAllRights: [],
  render: () => <LanePlanningHome/>,
};
