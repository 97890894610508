import {
  EWorkZonesManagementRights,
} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {MapsPrepareForOfflineUse} from "../pages/MapsPrepareForOfflineUse";
import {SaveMapsIcon} from "../assets/icons";

export const routeSaveOfflineMaps: IAppRoute = {
  title: 'Save Offline Maps',
  subtitle: 'Download for offline use',
  description: 'Download map areas in you device for offline use',
  icon: <SaveMapsIcon/>,
  routePath: '/cims/offline-preparation/download-maps',
  getRoutePath: () => routeSaveOfflineMaps.routePath,
  menuId: 'save-offline-maps',
  userHasAllRights: [EWorkZonesManagementRights.WORKZONES_EDIT],
  userHasAnyOfRights: [],
  render: () => <MapsPrepareForOfflineUse/>,
};
