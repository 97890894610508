import {EDeviceManagementRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {DeviceManagementTreeIcon} from "../assets/icons";

export const routeDeviceManagementVirtualTreePaths: IAppRoute<{ presetId: string }> = {
  menuId: 'device-management-tree',
  icon: <DeviceManagementTreeIcon/>,
  title: 'Devices list',
  subtitle: 'Manage devices',
  description: 'Manage devices in hierarchical tree',
  routePath: '/device-management/tree/:presetId?',
  getRoutePath: ({presetId} = {presetId: ''}) =>
    routeDeviceManagementVirtualTreePaths.routePath
      .replace(':presetId?', presetId),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
    EDeviceManagementRights.CONFIG_DEVICES,
  ],
  render: () => null,
};
