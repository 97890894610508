import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

const UserProfilePage = lazy(() => import("../pages/UserProfilePage"));

export const routeUserProfilePage: IAppRoute = {
  menuId: '',
  title: 'User Profile',
  routePath: '/user-profile',
  getRoutePath: () => routeUserProfilePage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  render: () => (
    <LazyLoadComponent>
      <UserProfilePage/>
    </LazyLoadComponent>
  ),
};
