import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";

const SignInPage = lazy(() => import("../pages/SignInPage"));

export const routeSignInPage: IAppRoute = {
  menuId: '',
  title: 'Login',
  routePath: '/sign-in',
  getRoutePath: () => routeSignInPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => (
    <LazyLoadComponent>
      <SignInPage/>
    </LazyLoadComponent>
  ),
};
