import {IAppRoute} from "../../application/config/IAppRoute";
import {WorkzoneCRUDPage} from "../pages/WorkzoneCRUDPage";
import {routeWorkzoneCreatePaths} from "./routeWorkzoneCreate.paths";
import {
  ECIMTabRoot,
  ECIMTabMap,
} from "../components/WorkzoneForm";

export const routeWorkzoneCreate: IAppRoute<{
  tabRoot?: ECIMTabRoot;
  tabMap?: ECIMTabMap;
}> = {
  ...routeWorkzoneCreatePaths,
  render: (
    {
      pathParams: {
        tabRoot,
        tabMap,
      },
    },
  ) => (
    <WorkzoneCRUDPage
      tabRoot={tabRoot}
      tabMap={tabMap}
    />
  ),
};
