import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {WrongWayResolutionCreateZipTestPage} from "../pages/WrongWayResolutionCreateZipTestPage";

import ZipIcon from '@mui/icons-material/FolderZip';

export const routeWrongWayResolutionCreateZipTestPage: IAppRoute = {
  menuId: '',
  title: 'Test Zip creation',
  subtitle: 'Upload and zip test',
  description: "Test server's capability to zip files. This feature is used by the Wrong Way Resolution plugin",
  icon: <ZipIcon/>,
  routePath: '/app/test/WWRZipCreation',
  getRoutePath: () => routeWrongWayResolutionCreateZipTestPage.routePath,
  userHasAllRights: [EUserAuthenticationRights.ADMINISTRATOR],
  userHasAnyOfRights: [],
  render: () => <WrongWayResolutionCreateZipTestPage/>,
};
