import {ETrafficStudiesRights} from "mhc-server/dist/interfaces";

import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {IAppRoute} from "../../application/config/IAppRoute";

import {TrafficStudyReportsPage} from "../pages/TrafficStudyReportsPage";

export const routeTrafficStudyReports: IAppRoute<{
  trafficStudyId: string;
  reportId?: string;
}> = {
  menuId: '',
  title: 'Traffic Study Reports',
  routePath: '/traffic-studies/reports/:trafficStudyId/:reportId?',
  getRoutePath: (args) => buildUrlByRoutePath({
    routePath: routeTrafficStudyReports.routePath,
    pathParams: args,
  }),
  userHasAllRights: [],
  userHasAnyOfRights: [ETrafficStudiesRights.READ],
  render: (
    {
      pathParams: {
        trafficStudyId,
        reportId,
      },
    },
  ) => (
    <TrafficStudyReportsPage
      trafficStudyId={trafficStudyId}
      reportId={reportId}
    />
  ),
};
