import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

export const routeUserInvitationManagePaths: IAppRoute<{
  email: string | 'create-a-new-one';
}> = {
  menuId: '',
  title: 'Edit invitation',
  routePath: '/users-invitations/manage/:email/',
  getRoutePath: ({email} = {email: ''}) =>
    routeUserInvitationManagePaths.routePath
      .replace(':email', email),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  render: () => null,
};
