import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../config/IAppRoute";
import {AppMainPage} from "../pages/AppMainPage";

export const routeAppMainPage: IAppRoute = {
  menuId: '',
  title: 'Home page',
  routePath: '/',
  getRoutePath: () => routeAppMainPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  render: () => <AppMainPage/>,
};
