import {
  EWorkZonesManagementRights,
} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {WorkzonesListPage} from "../pages/WorkzonesListPage";

import {CIMsListIcon} from "../assets/icons";

export const routeWorkZonesList: IAppRoute<{ search?: string }> = {
  title: 'CIMs Management',
  subtitle: 'Manage the CIM items',
  description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
  icon: <CIMsListIcon/>,
  routePath: '/cims/list/:search?/',
  getRoutePath: () => routeWorkZonesList.routePath,
  menuId: 'cims-list',
  userHasAllRights: [],
  userHasAnyOfRights: [
    EWorkZonesManagementRights.WORKZONES_VIEW,
    EWorkZonesManagementRights.WORKZONES_EDIT,
  ],
  render: ({pathParams: {search = ""}}) =>
    <WorkzonesListPage
      search={search}
    />,
};
