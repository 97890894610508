import {EAlertsManagementRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {AlertMgtmSettingsPage} from "../pages/AlertMgtmSettingsPage";
import {SettingsIcon} from "../../../assets/icons";

export const routeAlertMgtmSettingsPage: IAppRoute = {
  menuId: '###-alert-mgtm-settings-page',
  title: "Settings",
  subtitle: 'Alert management settings',
  description: 'Configure the Alerts Management feature',
  icon: <SettingsIcon/>,
  routePath: '/alerts-management/settings',
  getRoutePath: () => routeAlertMgtmSettingsPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [
    EAlertsManagementRights.ALERT_CONFIG_VIEW,
    EAlertsManagementRights.ALERT_CONFIG_EDIT,
  ],
  render: () => <AlertMgtmSettingsPage />,
};
