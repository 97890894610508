import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IWorkzoneCRUDPageProps} from "./WorkzoneCRUDPage";

const WorkzoneCRUDPageLazy = lazy(() => import("./WorkzoneCRUDPage"));

export const WorkzoneCRUDPage = connect((props: IWorkzoneCRUDPageProps) => (
  <LazyLoadComponent>
    <WorkzoneCRUDPageLazy {...props}/>
  </LazyLoadComponent>
));
