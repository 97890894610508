import {ETrafficStudiesRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

import {
  TrafficStudyManage,
  ETrafficStudyManageTab,
} from "../pages/TrafficStudyManage";

import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {TrafficStudiesFeatureIcon} from "../assets/icons";

export const routeTrafficStudyManage: IAppRoute<{
  trafficStudyId: string;
  tab?: ETrafficStudyManageTab;
  tabArgument?: string;
}> = {
  menuId: "menu--trafficStudy-manage",
  title: 'Manage Traffic Study',
  icon: <TrafficStudiesFeatureIcon/>,
  routePath: '/traffic-studies/manage/:trafficStudyId/:tab?/:tabArgument?',
  getRoutePath: args =>
    buildUrlByRoutePath({
      routePath: routeTrafficStudyManage.routePath,
      pathParams: args,
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [
    ETrafficStudiesRights.READ,
    ETrafficStudiesRights.WRITE,
  ],
  render: (
    {
      pathParams: {
        trafficStudyId,
        tab,
        tabArgument,
      },
    }) =>
    <TrafficStudyManage
      trafficStudyId={trafficStudyId}
      tab={tab}
      tabArgument={tabArgument}
    />,
};
