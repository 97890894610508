import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

import {IAppRoute} from "../../application/config/IAppRoute";
import {routeUserEditPagePaths} from "./routeUserEditPage.paths";

const UserCRUDPage = lazy(() => import("../pages/UserCRUDPage/UserCRUDPage"));

export const routeUserEditPage: IAppRoute<{
  userId: string;
}> = {
  ...routeUserEditPagePaths,
  render: ({pathParams: {userId}}) => (
    <LazyLoadComponent>
      <UserCRUDPage userId={userId}/>
    </LazyLoadComponent>
  ),
};
