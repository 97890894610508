import {IAppRoute} from "./IAppRoute";
import {routesAppCore} from "../routes";
import {routesUserManagement} from "../../user-management";
import {routesWorkZonesManagement} from "../../workzones-management/routes";
import {routesUserAuthentication} from "../../user-authnentication";
import {routesUserInvitations} from "../../users-invitations";
import {routesDeviceManagement} from "../../device-management";
import {routesWrongWayResolution} from "../../wrong-way-resolution";
import {routesAlertsManagement} from "../../alerts-management";
import {routesLanePlanning} from "../../lane-planning";
import {routesTrafficStudies} from "../../traffic-studies";

export const appRoutes: IAppRoute[] = Array<IAppRoute>().concat(
  routesAppCore,
  routesUserManagement,
  routesUserAuthentication,
  routesUserInvitations,
  routesWorkZonesManagement,
  routesDeviceManagement,
  routesWrongWayResolution,
  routesAlertsManagement,
  routesLanePlanning,
  routesTrafficStudies,
);
