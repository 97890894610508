import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ILanePlanningMainPageProps} from "./LanePlanningMainPage";

const LanePlanningHomeLazy = lazy(() => import("./LanePlanningMainPage"));

export const LanePlanningHome = connect((props: ILanePlanningMainPageProps) => (
  <LazyLoadComponent>
    <LanePlanningHomeLazy {...props}/>
  </LazyLoadComponent>
));
