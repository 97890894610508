import {EAlertsManagementRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {AlertsMgtmMainPage} from "../pages/AlertsMgtmMainPage";
import {AlertSectionIcon} from "../assets/icons";

export const routeAlertMgtmMainPage: IAppRoute = {
  menuId: '###-alert-mgtm-list-page',
  title: 'Alerts Management',
  subtitle: 'Lorem ipsum dolor sit amet.',
  description: 'Watch and manage alerts',
  icon: <AlertSectionIcon/>,
  routePath: '/alerts-management',
  getRoutePath: function () {
    return this.routePath;
  },
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(EAlertsManagementRights),
  render: () => <AlertsMgtmMainPage/>,
};
