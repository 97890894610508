import { jsx as _jsx } from "react/jsx-runtime";
import { isChromium, isSafari, } from "utils-library/dist/web";
import { Condition } from "../../../Condition";
import { GlobalStyles } from "../../../GlobalStyles";
export const GlobalScrollbars = () => {
    return (_jsx(Condition, { if: isChromium || isSafari, then: _jsx(GlobalStyles, { styles: {
                '*::-webkit-scrollbar': { width: '0.4em' },
                '*::-webkit-scrollbar-track': { '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)' },
                '*::-webkit-scrollbar-thumb': { backgroundColor: "rgba(164,164,164,0.50)" },
            } }), else: _jsx(GlobalStyles, { styles: {
                '*': {
                    scrollbarWidth: 'thin',
                    scrollbarColor: 'rgba(164,164,164,0.50) rgba(164,164,164,0.05)',
                },
            } }) }));
};
