import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IDeviceMgtmSettingsProps} from "./DeviceMgtmSettings";

const DeviceMgtmSettingsLazy = lazy(() => import("./DeviceMgtmSettings"));

export const DeviceMgtmSettings = connect((props: IDeviceMgtmSettingsProps) => (
  <LazyLoadComponent>
    <DeviceMgtmSettingsLazy {...props}/>
  </LazyLoadComponent>
));
