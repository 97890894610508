import {
  EWorkZonesManagementRights,
} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";

import {WorkzonesPrepareForOffline} from "../pages/WorkzonesPrepareForOffline";
import {CIMsOfflinePrepareIcon} from "../assets/icons";

export const routeWorkzoneOfflinePrepare: IAppRoute = {
  title: 'Prepare CIMs for offline use',
  subtitle: "Download CIM for offline use",
  description: "Download CIMs in you device to access them in offline use",
  icon: <CIMsOfflinePrepareIcon/>,
  routePath: '/cims/offline-preparation/download-cims',
  getRoutePath: () => routeWorkzoneOfflinePrepare.routePath,
  menuId: 'CIMs-prepare-for-offline-use',
  userHasAllRights: [EWorkZonesManagementRights.WORKZONES_EDIT],
  userHasAnyOfRights: [],
  render: () => <WorkzonesPrepareForOffline/>,
};
