import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ICIMsMainPageProps} from "./CIMsMainPage";

const CIMsMainPageLazy = lazy(() => import("./CIMsMainPage"));

export const CIMsMainPage = connect((props: ICIMsMainPageProps) => (
  <LazyLoadComponent>
    <CIMsMainPageLazy {...props}/>
  </LazyLoadComponent>
));
