import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IWorkzonesListPageProps} from "./WorkzonesListPage";

const WorkzonesListPageLazy = lazy(() => import("./WorkzonesListPage"));

export const WorkzonesListPage = connect((props: IWorkzonesListPageProps) => (
  <LazyLoadComponent>
    <WorkzonesListPageLazy {...props}/>
  </LazyLoadComponent>
));
