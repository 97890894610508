import {buildUrlByRoutePath} from "utils-library/dist/utils";
import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {UsersListPageIcon} from "../assets/icons";

export const routeListUsersPagePaths: IAppRoute<{
  search?: string;
}> = {
  menuId: '##menu--userManagement-list-page',
  icon: <UsersListPageIcon/>,
  title: 'Users',
  subtitle: 'List and manage the users',
  description: "Manipulate users, roles and permissions",
  routePath: '/users-management/list/:search?/',
  getRoutePath: (args = {}) =>
    buildUrlByRoutePath({
      routePath: routeListUsersPagePaths.routePath,
      pathParams: args,
    }),
  userHasAllRights: [],
  userHasAnyOfRights: [
    EUserAuthenticationRights.ADMINISTRATOR,
    EUserAuthenticationRights.SYSTEM_ADMINISTRATOR,
  ],
  render: () => null,
};
