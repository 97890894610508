import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAppAboutPageProps} from "./AppAboutPage";

const AppAboutPageLazy = lazy(() => import("./AppAboutPage"));

export const AppAboutPage = (props: IAppAboutPageProps) => (
  <LazyLoadComponent>
    <AppAboutPageLazy {...props}/>
  </LazyLoadComponent>
);
