import {ICreateStoreAPI} from "dynadux";
import {IDynaError} from "dyna-error";
import {dynaError} from "dyna-error";

import {
  IUser,
  getDefaultUser,
} from "mhc-server/dist/interfaces";

import {EAppAction} from "../../application/state/appSection";
import {INavigateTo} from "../../application/interfaces/INavigateTo";

import {apiAuthCurrentUserInfoGet} from "../api/apiAuthCurrentUserInfoGet";
import {apiAuthLogoutGet} from "../api/apiAuthLogoutGet";
import {localSessionIdManager} from "./utils/localSessionsId";

import {routeSignInPage} from "../routes/routeSignInPage";
import {routeSignInWithInvitationPagePaths} from "../routes/routeSignInWithInvitationPage.paths";

export enum EUserAuthAction {
  SIGN_OAUTH_IN = "USER_AUTH__SIGN_OAUTH_IN",               // Payload ISIGN_IN_payload
  SIGN_OUT = "USER_AUTH__SIGN_OUT",                         // Payload ISIGN_OUT_payload
  SIGNED_IN = "USER_AUTH__SIGNED_IN",                       // Payload void
  SET_STATUS = "USER_AUTH__SET_STATUS",                     // Payload ESignStatus
  SET_SIGNED_USER = "USER_AUTH__SET_SIGNED_USER",           // Payload userTokenExpiresAt
  SET_AUTH_ERROR = "USER_AUTH__SET_AUTH_ERROR",             // Payload IDynaError | null | unknown
  SET_USER_RIGHTS = "USER_AUTH__SET_USER_RIGHTS",           // Payload string[]
  SET_LOCAL_SESSION_ID = "USER_AUTH__SET_LOCAL_SESSION_ID", // Payload userId: string
  REFRESH_TOKEN = "USER_AUTH__REFRESH_TOKEN",               // Payload IREFRESH_TOKEN_payload
}

export enum ESignStatus {
  SIGNING_IN = "SIGNING_IN",    // The ...ing statuses are for a sort time, the initiator is responsible to change the in ...ed statuses
  SIGNING_UP = "SIGNING_UP",
  SIGNING_OUT = "SIGNING_OUT",
  SIGNED_IN = "SIGNED_IN",      // The ..ed statuses are the resolved status of the above operations/statuses
  SIGNED_OUT = "SIGNED_OUT",
}

interface ISIGN_IN_payload {
  successRedirectionPath?: string;
  signWithInvitation?: boolean;
}

export interface ISIGN_OUT_payload {
  authError?: IDynaError | null | unknown;
  networkSignOff?: boolean;
}

interface ISET_SIGNED_USER_payload {
  user: IUser;
  userTokenExpiresAt: number;
}

interface IREFRESH_TOKEN_payload {
  customTokenExpirationInDays?: number;
  onComplete?: (signedIn: boolean) => void;
}

export interface IUserAuthSection {
  signStatus: ESignStatus;
  user: IUser;
  userTokenExpiresAt: number;
  rightsDic: { [right: string]: boolean };
  authError?: IDynaError | null;
  localSessionId: string;         // Needed for local purposes. There are no server sessions!
}

const userAuthSectionInitialState = (): IUserAuthSection => ({
  signStatus: ESignStatus.SIGNED_OUT,
  user: getDefaultUser(),
  userTokenExpiresAt: 0,
  rightsDic: {}, // Updated by user.rights, used for faster access.
  authError: null,
  localSessionId: localSessionIdManager.getSessionId(),
});

export const createUserAuthenticationSection = (store: ICreateStoreAPI) => {
  const section = store.createSection<IUserAuthSection>({
    section: 'userAuth',
    initialState: userAuthSectionInitialState(),
    reducers: {
      [EAppAction.RESET_APP]: (): IUserAuthSection => {
        return userAuthSectionInitialState();
      },
      [EUserAuthAction.SIGN_OAUTH_IN]: (
        {
          payload,
          dispatch,
        },
      ): void => {
        // Asks the server if the user is already signed in (checking the current token, if any).
        // If it is signed in, save the user on the state and redirect the user to the payload's successRedirectionPath (where the user was).
        // If it is not signed, redirect the user sign-in page.
        (async () => {
          try {
            dispatch<ESignStatus>(EUserAuthAction.SET_STATUS, ESignStatus.SIGNING_IN);
            const {
              successRedirectionPath = '/',
              signWithInvitation = false,
            }: ISIGN_IN_payload = payload;
            const {
              signedIn,
              user,
              authErrorMessage,
              tokenExpiresAt,
            } = await apiAuthCurrentUserInfoGet();

            if (signedIn && user) {
              // At this point the server returned the already signed in user, there was no need for oAuth redirection.
              dispatch<ISET_SIGNED_USER_payload>(
                EUserAuthAction.SET_SIGNED_USER,
                {
                  user,
                  userTokenExpiresAt: tokenExpiresAt,
                },
              );
              dispatch<string>(EUserAuthAction.SET_LOCAL_SESSION_ID, user.idHash);
              dispatch<INavigateTo>(EAppAction.NAVIGATE_TO, {url: successRedirectionPath});
              dispatch<void>(EUserAuthAction.SIGNED_IN);
            }
            else {
              // At this point the user is checked that is not signed in
              // Save the page where he is now, to redirect once the Auth is success
              // And navigate the user the Sign In page
              localStorage.setItem('app_Auth__successRedirectionPath', successRedirectionPath);
              if (authErrorMessage) {
                dispatch<IDynaError>(
                  EUserAuthAction.SET_AUTH_ERROR,
                  dynaError({
                    message: 'Server sign error',
                    userMessage: authErrorMessage,
                  }),
                );
              }
              dispatch<ESignStatus>(EUserAuthAction.SET_STATUS, ESignStatus.SIGNED_OUT);
              dispatch<string>(EUserAuthAction.SET_LOCAL_SESSION_ID, '');
              dispatch<INavigateTo>(
                EAppAction.NAVIGATE_TO,
                {
                  url: signWithInvitation
                    ? routeSignInWithInvitationPagePaths.routePath
                    : routeSignInPage.routePath,
                  replace: true,
                },
              );
            }
          }
          catch (e) {
            console.error('EUserAuthAction.SIGN_OAUTH_IN, General sign-in error 20231228191325', e);
            dispatch<ISIGN_OUT_payload>(EUserAuthAction.SIGN_OUT, {authError: e as any});
          }
        })();
      },
      [EUserAuthAction.SET_SIGNED_USER]: (
        {
          payload,
          dispatch,
        },
      ): Partial<IUserAuthSection> => {
        const {
          user, userTokenExpiresAt,
        }: ISET_SIGNED_USER_payload = payload;
        dispatch<string[]>(EUserAuthAction.SET_USER_RIGHTS, user.rights);
        dispatch<ESignStatus>(EUserAuthAction.SET_STATUS, ESignStatus.SIGNED_IN);
        return {
          user,
          userTokenExpiresAt,
        };
      },
      [EUserAuthAction.SIGN_OUT]: (
        {
          dispatch,
          payload,
        },
      ): void => {
        (async () => {
          const {
            authError,
            networkSignOff = true,
          }: ISIGN_OUT_payload = payload;
          dispatch<ESignStatus>(EUserAuthAction.SET_STATUS, ESignStatus.SIGNING_OUT);
          await new Promise(r => setTimeout(r, 10));
          try {
            await apiAuthLogoutGet(networkSignOff);
          }
          catch (e) {
            console.error('Error signing out', e);
          }
          localSessionIdManager.clear();
          section.dispatch<void>(EAppAction.RESET_APP);
          if (authError) dispatch<IDynaError | unknown>(EUserAuthAction.SET_AUTH_ERROR, authError);
          dispatch<INavigateTo>(EAppAction.NAVIGATE_TO, {url: routeSignInPage.getRoutePath()});
        })();
      },
      [EUserAuthAction.SET_STATUS]: ({payload}): Partial<IUserAuthSection> => {
        const signStatus: ESignStatus = payload;
        return {signStatus};
      },
      [EUserAuthAction.SET_AUTH_ERROR]: ({payload}): Partial<IUserAuthSection> => {
        const authError: IDynaError | null = payload;
        return {authError};
      },
      [EUserAuthAction.SET_USER_RIGHTS]: (
        {
          payload,
          state,
        },
      ): Partial<IUserAuthSection> => {
        const rights: string[] = payload;
        return {
          user: {
            ...state.user,
            rights,
          },
          rightsDic: rights
            .reduce((acc: { [right: string]: boolean }, right) => {
              acc[right] = true;
              return acc;
            }, {}),
        };
      },
      [EUserAuthAction.SET_LOCAL_SESSION_ID]: ({payload}): Partial<IUserAuthSection> => {
        const userIdHash: string = payload;
        if (userIdHash) {
          return {localSessionId: localSessionIdManager.getSessionIdByUser(userIdHash)};
        }
        else {
          localSessionIdManager.clear();
          return {localSessionId: ''};
        }
      },
      [EUserAuthAction.REFRESH_TOKEN]: (
        {
          payload,
          dispatch,
        },
      ): void => {
        (async () => {
          const {
            customTokenExpirationInDays,
            onComplete,
          }: IREFRESH_TOKEN_payload = payload;

          try {
            const {
              signedIn,
              user,
              tokenExpiresAt,
            } = await apiAuthCurrentUserInfoGet({customTokenExpirationInDays});

            if (!signedIn || !user) {
              // The user is not valid for some reason
              // Start the Sign in process
              onComplete && onComplete(false);
              dispatch<ISIGN_IN_payload>(EUserAuthAction.SIGN_OAUTH_IN, {});
              return;
            }

            onComplete && onComplete(true);
            dispatch<ISET_SIGNED_USER_payload>(
              EUserAuthAction.SET_SIGNED_USER,
              {
                user,
                userTokenExpiresAt: tokenExpiresAt,
              },
            );
          }
          catch (e: any) {
            console.error('EUserAuthAction.REFRESH_TOKEN General error 20231228191323', e);
            dispatch<ISIGN_OUT_payload>(EUserAuthAction.SIGN_OUT, {authError: e as any});
          }
        })();
      },
    },
  });

  return {
    get state(): IUserAuthSection {
      return section.state;
    },
    actions: {
      signIn: (successRedirectionPath: string, signWithInvitation: boolean = false): void => {
        section.dispatch<ISIGN_IN_payload>(EUserAuthAction.SIGN_OAUTH_IN, {
          successRedirectionPath,
          signWithInvitation,
        });
      },
      signOut: (authError: IDynaError | null): void => {
        section.dispatch<ISIGN_OUT_payload>(EUserAuthAction.SIGN_OUT, {authError});
      },
      setUserRights: (rights: string[]): void => section.dispatch<string[]>(EUserAuthAction.SET_USER_RIGHTS, rights),
      setUserAuthError: (error: IDynaError): void => section.dispatch<IDynaError>(EUserAuthAction.SET_AUTH_ERROR, error),
      clearUserAuthError: (): void => section.dispatch<IDynaError | null>(EUserAuthAction.SET_AUTH_ERROR, null),
      refreshToken: (customTokenExpirationInDays?: number): Promise<boolean> => {
        return new Promise(resolve => {
          section.dispatch<IREFRESH_TOKEN_payload>(
            EUserAuthAction.REFRESH_TOKEN,
            {
              customTokenExpirationInDays,
              onComplete: resolve,
            },
          );
        });
      },
    },
    utils: {
      userHasRight: (right: string): boolean => {
        return !!section.state.rightsDic[right];
      },
      userHasAnyOfRights: (rights?: string[]): boolean => {
        if (!rights) return true;
        if (!rights.length) return true;
        return !!rights.find(right => !!section.state.rightsDic[right]);
      },
      userHasAllRights: (rights?: string[]): boolean => {
        if (!rights) return true;
        if (!rights.length) return true;
        return rights.filter(right => !!section.state.rightsDic[right]).length === rights.length;
      },
    },
  };
};
