import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAlertsMgtmMainPageProps} from "./AlertsMgtmMainPage";

const AlertsMgtmMainPageLazy = lazy(() => import("./AlertsMgtmMainPage"));

export const AlertsMgtmMainPage = connect((props: IAlertsMgtmMainPageProps) => (
  <LazyLoadComponent>
    <AlertsMgtmMainPageLazy {...props}/>
  </LazyLoadComponent>
));
