import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IWorkzonesPrepareForOfflineProps} from "./WorkzonesPrepareForOffline";

const WorkzonesPrepareForOfflineLazy = lazy(() => import("./WorkzonesPrepareForOffline"));

export const WorkzonesPrepareForOffline = connect((props: IWorkzonesPrepareForOfflineProps) => (
  <LazyLoadComponent>
    <WorkzonesPrepareForOfflineLazy {...props}/>
  </LazyLoadComponent>
));
