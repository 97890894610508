import {EUserAuthenticationRights} from "mhc-server/dist/interfaces";

import {SettingsIcon} from "../../../assets/icons";
import {IAppRoute} from "../config/IAppRoute";
import {AppOptions} from "../pages/AppSettingsPage";

export const routeAppSettingsPage: IAppRoute = {
  menuId: '##app-settings',
  icon: <SettingsIcon/>,
  title: 'Settings',
  subtitle: "App's settings",
  description: "General environment application settings",
  routePath: '/app/settings',
  getRoutePath: () => routeAppSettingsPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [EUserAuthenticationRights.ACTIVE],
  render: () => <AppOptions/>,
};
