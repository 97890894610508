import {IAppRoute} from "../../application/config/IAppRoute";

import {routeAlertMgtmMainPage} from "./routeAlertMgtmMainPage";
import {routeAlertMgtmListPage} from "./routeAlertMgtmListPage";
import {routeAlertMgtmSettingsPage} from "./routeAlertMgtmSettingsPage";
import {routeAlertMgtmTestPage} from "./routeAlertMgtmTestPage";

export const routesAlertsManagement: IAppRoute<any>[] = [
  routeAlertMgtmMainPage,
  routeAlertMgtmListPage,
  routeAlertMgtmSettingsPage,
  routeAlertMgtmTestPage,
];
