import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";

const CheckAppVersionLazy = lazy(() => import("./CheckAppVersion"));

export const CheckAppVersion = () => (
  <LazyLoadComponent>
    <CheckAppVersionLazy/>
  </LazyLoadComponent>
);
