import {EDeviceManagementRights} from "mhc-server/dist/interfaces";

import {buildUrlByRoutePath} from "utils-library/dist/utils";

import {IAppRoute} from "../../application/config/IAppRoute";
import {DeviceManagementMapIcon} from "../assets/icons";

export const routeDeviceManagementMapPaths: IAppRoute<{ searchObjectText: string }> = {
  menuId: 'device-management/map',
  icon: <DeviceManagementMapIcon/>,
  title: 'Map',
  subtitle: 'Live map with devices',
  description: 'Watch and manage the devices using the map',
  routePath: '/device-management/map/:searchObjectText?/',
  getRoutePath: ({searchObjectText} = {searchObjectText: ''}) =>
    buildUrlByRoutePath({
      routePath: routeDeviceManagementMapPaths.routePath,
      pathParams: {searchObjectText},
    }),
  userHasAllRights: [
  ],
  userHasAnyOfRights: [
    EDeviceManagementRights.VIEW,
  ],
  render: () => null,
};
