import {IAppRoute} from "../../application/config/IAppRoute";

import {routeCIMsMainPage} from "./routeCIMsMainPage";
import {routeWorkZonesList} from "./routeWorkZonesList";
import {routeWorkzoneCreate} from "./routeWorkzoneCreate";
import {routeWorkZonesEdit} from "./routeWorkzoneEdit";
import {routeWorkzonesAppSettings} from "./routeWorkzonesAppSettings";

import {routeWorkzoneOfflinePrepare} from "./routeWorkzoneOfflinePrepare";
import {routeSaveOfflineMaps} from "./routeSaveOfflineMaps";

export const routesWorkZonesManagement: IAppRoute<any, any>[] = [
  routeCIMsMainPage,
  routeWorkZonesList,
  routeWorkzoneCreate,
  routeWorkZonesEdit,
  routeWorkzoneOfflinePrepare,
  routeSaveOfflineMaps,
  routeWorkzonesAppSettings,
];
