import {IAppRoute} from "../../application/config/IAppRoute";

import {routeTrafficStudyMainPage} from "./routeTrafficStudyMainPage";
import {routeTrafficStudyList} from "./routeTrafficStudyList";
import {routeTrafficStudyReports} from "./routeTrafficStudyReports";
import {routeTrafficStudyManage} from "./routeTrafficStudyManage";

export const routesTrafficStudies: IAppRoute<any, any>[] = [
  routeTrafficStudyMainPage,
  routeTrafficStudyList,
  routeTrafficStudyReports,
  routeTrafficStudyManage,
];
