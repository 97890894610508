import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAlertMgtmTestPageProps} from "./AlertMgtmTestPage";

const AlertMgtmTestPageLazy = lazy(() => import("./AlertMgtmTestPage"));

export const AlertMgtmTestPage = connect((props: IAlertMgtmTestPageProps) => (
  <LazyLoadComponent>
    <AlertMgtmTestPageLazy {...props}/>
  </LazyLoadComponent>
));
