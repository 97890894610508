import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {INotFound404PageProps} from "./NotFound404Page";

const NotFound404PageLazy = lazy(() => import("./NotFound404Page"));

export const NotFound404Page = (props: INotFound404PageProps) => (
  <LazyLoadComponent>
    <NotFound404PageLazy {...props}/>
  </LazyLoadComponent>
);
