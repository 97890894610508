import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IAppSettingsPageProps} from "./AppSettingsPage";

const AppOptionsLazy = lazy(() => import("./AppSettingsPage"));

export const AppOptions = connect((props: IAppSettingsPageProps) => (
  <LazyLoadComponent>
    <AppOptionsLazy {...props}/>
  </LazyLoadComponent>
));
