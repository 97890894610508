import {ETrafficStudiesRights} from "mhc-server/dist/interfaces";
import {IAppRoute} from "../../application/config/IAppRoute";
import {TrafficStudiesFeatureIcon} from "../assets/icons";
import {TrafficStudiesMainPage} from "../pages/TrafficStudiesMainPage";

export const routeTrafficStudyMainPage: IAppRoute = {
  menuId: "menu--trafficStudies-mainPage",
  title: 'Traffic Studies',
  subtitle: 'Elit, sed do eiusmod tempor incididunt ut labore',
  description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  icon: <TrafficStudiesFeatureIcon/>,
  routePath: '/traffic-studies',
  getRoutePath: ()=>routeTrafficStudyMainPage.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: Object.values(ETrafficStudiesRights),
  render: ()=> <TrafficStudiesMainPage/>,
};
