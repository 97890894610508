import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {ITrafficStudiesMainPageProps} from "./TrafficStudiesMainPage";

const TrafficStudiesMainPageLazy = lazy(() => import("./TrafficStudiesMainPage"));

export const TrafficStudiesMainPage = connect((props: ITrafficStudiesMainPageProps) => (
  <LazyLoadComponent>
    <TrafficStudiesMainPageLazy {...props}/>
  </LazyLoadComponent>
));
