var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import Dexie from 'dexie';
import { guid } from "dyna-guid";
import { dynaError } from "dyna-error";
import { syncPromises } from "dyna-sync";
import { DynaJobQueue } from "dyna-job-queue";
import { searchTextEngine } from "utils-library/dist/utils";
const SEARCH_TEXT_FIELD_NAME = '__browserDb_dbSearchText';
export class BrowserDbCollection {
    constructor(config) {
        this.config = config;
        this.updateQueue = new DynaJobQueue({ parallels: 1 });
        this.get = (key) => __awaiter(this, void 0, void 0, function* () {
            if (!this.db.isOpen())
                yield this.db.open();
            const searchResults = yield this.db.table(this.collectionName)
                .where(this.config.keyFieldName)
                .equals(key)
                .toArray()
                .then(this.convertDbDocsToDocs);
            const doc = searchResults[0];
            if (!doc)
                return null;
            return Object.assign({}, doc);
        });
        this.convertDocToDbDoc = (doc) => {
            const { buildSearchContent } = this.config;
            const _doc = Object.assign({}, doc);
            // @ts-ignore
            _doc[SEARCH_TEXT_FIELD_NAME] =
                buildSearchContent
                    ? buildSearchContent(doc).join(' ')
                    : '';
            return _doc;
        };
        this.convertDbDocsToDocs = (doc) => {
            return doc.map(this.convertDbDocToDoc);
        };
        this.convertDbDocToDoc = (doc) => {
            const _doc = Object.assign({}, doc);
            // @ts-ignore
            delete _doc[SEARCH_TEXT_FIELD_NAME];
            return _doc;
        };
        const { dbName, version = 0, collectionName, indexFieldNames = [], keyFieldName, } = config;
        // Create db per collection since we don't know the number of collections in advance.
        this.db = new Dexie(`${dbName}--${collectionName}--v${version}`);
        this.db
            .version(1)
            .stores({
            [collectionName]: [
                keyFieldName,
                SEARCH_TEXT_FIELD_NAME,
                ...indexFieldNames,
            ].join(', '),
        });
        this.update = this.updateQueue.jobFactory(this.update.bind(this));
    }
    get collectionName() {
        return this.config.collectionName;
    }
    /**
     * Creates or updates (merging) a document by the keyFieldName of the collection
     * If the fields with name keyFieldName has not value, this methods assigns a guid and creates the document
     * @param doc
     * @param [params] - Parameters for the update
     * @param [params.newerOnly] - If the document doesn't exist, create it. If exists, update it if the updateAt is newer this the existed one.
     * @param [params.externalImport] - When the item doesn't exist locally, preserve the timestamps as this update is an import from an external DB resource.
     */
    update(doc, params) {
        return __awaiter(this, void 0, void 0, function* () {
            yield new Promise(r => setTimeout(r, 2)); // Intentional delay, since on faster machines the updatedAt timestamp ends up with the same value, causing issues with DB sync operations!
            const _doc = Object.assign({}, doc);
            const { newerOnly = false, merge = true, externalImport = false, } = params || {};
            if (localStorage.getItem('_debug_offlineMessages') === 'true') {
                console.log('DEBUG-OFFLINE: DB_BROWSER_COLLECTION__UPDATE', this.config.collectionName, {
                    doc,
                    params,
                });
            }
            if (!this.db.isOpen())
                yield this.db.open();
            const now = Date.now();
            // Has no key, it is new, create key and create doc
            if (!_doc[this.config.keyFieldName]) {
                const newKey = _doc[this.config.keyFieldName] = guid();
                _doc.createdAt = now;
                _doc.updatedAt = now;
                _doc.deletedAt = 0;
                yield this.db.table(this.collectionName).add(this.convertDocToDbDoc(_doc));
                if (!externalImport && this.config.onCreate)
                    yield this.config.onCreate(_doc);
                return newKey;
            }
            // It has key, find it and update it
            const docKey = _doc[this.config.keyFieldName];
            if (_doc.createdAt === undefined)
                _doc.createdAt = now;
            if (_doc.updatedAt === undefined)
                _doc.updatedAt = now;
            if (_doc.deletedAt === undefined)
                _doc.deletedAt = 0;
            const currentDoc = yield this.get(docKey);
            if (!externalImport)
                _doc.updatedAt = now;
            if (newerOnly && currentDoc && currentDoc.updatedAt > _doc.updatedAt) {
                return docKey; // Exit, do not update, the existed doc is newer
            }
            if (currentDoc) {
                const updatedFullDoc = merge
                    ? Object.assign(Object.assign({}, currentDoc), _doc) : Object.assign({ [this.config.keyFieldName]: currentDoc[this.config.keyFieldName] }, _doc);
                yield this.db.table(this.collectionName).update(docKey, this.convertDocToDbDoc(updatedFullDoc));
                if (!externalImport && this.config.onUpdate)
                    yield this.config.onUpdate(docKey, _doc);
                return docKey;
            }
            // Cannot be found to update it, so create it locally
            // This is also the same importing items in this db from anither db
            if (!externalImport) {
                _doc.createdAt = now;
                _doc.updatedAt = now;
                _doc.deletedAt = 0;
            }
            try {
                yield this.db.table(this.collectionName).add(this.convertDocToDbDoc(_doc));
            }
            catch (e) {
                const error = dynaError(e);
                if (error.message.startsWith("Failed to execute 'add' on 'IDBObjectStore'")) {
                    throw dynaError({
                        code: 202104090858,
                        message: `The keyFieldName of the [${this.config.collectionName}] collection is currently [${this.config.keyFieldName.toString()}] but the indexedDB has a different one. IndexedDB cannot save this document. You have to drop and re-create the collection.`,
                        parentError: error,
                        data: {
                            collectionConfig: this.config,
                            doc: _doc,
                        },
                    });
                }
                throw error;
            }
            if (!externalImport && this.config.onCreate)
                yield this.config.onCreate(_doc).catch();
            return docKey;
        });
    }
    /**
     * Create or update bulk docs.
     * @param docs
     * @param [params] - Parameters for the update
     * @param [params.newerOnly] - If the document doesn't exist, create it. If exists, update it if the updateAt is newer this the existed one.
     * @param [params.externalImport] - When the item doesn't exist locally, preserve the timestamps as this update is an import from an external DB resource.
     */
    updateMany(docs, params) {
        return __awaiter(this, void 0, void 0, function* () {
            const _docs = docs.concat();
            return syncPromises(..._docs.map(doc => () => this.update(doc, params)));
        });
    }
    getMany(keys) {
        return Promise.all(keys.map(this.get));
    }
    getAll() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db.isOpen())
                yield this.db.open();
            return this.db.table(this.collectionName).toArray();
        });
    }
    exists(key) {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db.isOpen())
                yield this.db.open();
            const doc = yield this.get(key);
            return !!doc;
        });
    }
    search(_a) {
        return __awaiter(this, arguments, void 0, function* ({ searchText, sort, deleted = false, pagination, }) {
            const { collectionName, buildSearchContent, } = this.config;
            if (!buildSearchContent)
                console.error(`BrowserDbCollection: collection [${collectionName}] has no the "buildSearchContent" defined and the .search() method called. You have to implemented the "buildSearchContent" on the collection in order to use the search().`);
            const search = searchText.toLocaleLowerCase();
            return this.find({
                filter: (doc) => {
                    const hasSearchContent = searchTextEngine({
                        searchText: search,
                        // @ts-ignore
                        sourceText: (doc[SEARCH_TEXT_FIELD_NAME] || '').toLocaleLowerCase(),
                    });
                    const isValidByDeleteFlag = deleted
                        ? doc.deletedAt !== 0
                        : doc.deletedAt === 0;
                    return hasSearchContent && isValidByDeleteFlag;
                },
                sort,
                pagination,
            });
        });
    }
    find(_a) {
        return __awaiter(this, arguments, void 0, function* ({ filter = () => true, sort, pagination, }) {
            if (!this.db.isOpen())
                yield this.db.open();
            if (sort && sort.direction === -1) {
                return this.db.table(this.collectionName)
                    .orderBy(sort.fieldName)
                    .filter(filter)
                    .reverse()
                    .offset(pagination.skip)
                    .limit(pagination.limit)
                    .toArray()
                    .then(this.convertDbDocsToDocs);
            }
            if (sort && sort.direction === 1) {
                return this.db.table(this.collectionName)
                    .orderBy(sort.fieldName)
                    .filter(filter)
                    .offset(pagination.skip)
                    .limit(pagination.limit)
                    .toArray()
                    .then(this.convertDbDocsToDocs);
            }
            return this.db.table(this.collectionName)
                .filter(filter)
                .offset(pagination.skip)
                .limit(pagination.limit)
                .toArray()
                .then(this.convertDbDocsToDocs);
        });
    }
    delete(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const now = Date.now();
            const doc = yield this.get(key);
            if (!doc)
                return false;
            if (doc.deletedAt)
                return false;
            doc.updatedAt = now;
            doc.deletedAt = now;
            const updatedDocKey = yield this.update(doc, { externalImport: true });
            if (!updatedDocKey)
                console.error('internal error: BrowserDbCollection cannot delete this doc because cannot be found', { key });
            if (!!updatedDocKey && this.config.onDelete)
                this.config.onDelete(key, doc);
            return !!updatedDocKey;
        });
    }
    undelete(key) {
        return __awaiter(this, void 0, void 0, function* () {
            const now = Date.now();
            const doc = yield this.get(key);
            if (!doc)
                return false;
            if (doc.deletedAt === 0)
                return false;
            doc.updatedAt = now;
            doc.deletedAt = 0;
            const updatedDocKey = yield this.update(doc, { externalImport: true });
            if (!updatedDocKey)
                console.error('internal error: BrowserDbCollection cannot delete this doc because cannot be found', { key });
            if (!!updatedDocKey && this.config.onUndelete)
                this.config.onUndelete(key, doc);
            return !!updatedDocKey;
        });
    }
    deletePermanent(key_1) {
        return __awaiter(this, arguments, void 0, function* (key, externalImport = false) {
            if (!this.db.isOpen())
                yield this.db.open();
            const deletedCount = yield this.db.table(this.collectionName)
                .where(this.config.keyFieldName)
                .equals(key)
                .delete();
            if (deletedCount > 1) {
                console.error('internal error: BrowserDbCollection delete(key:string): More that one deleted!', {
                    dbName: this.config.dbName,
                    collection: this.config.collectionName,
                    key,
                });
            }
            if (!externalImport && !!deletedCount && this.config.onPermanentDelete)
                this.config.onPermanentDelete(key);
            return !!deletedCount;
        });
    }
    drop() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db.isOpen())
                yield this.db.open();
            return this.db.delete();
        });
    }
    deleteAll() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db.isOpen())
                yield this.db.open();
            return this.db.table(this.collectionName).clear();
        });
    }
    count() {
        return __awaiter(this, void 0, void 0, function* () {
            if (!this.db.isOpen())
                yield this.db.open();
            return this.db.table(this.collectionName).count();
        });
    }
}
