import {connect} from "react-dynadux";

import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IWorkzonesAppSettingsProps} from "./WorkzonesAppSettings";

const WorkzonesAppSettingsLazy = lazy(() => import("./WorkzonesAppSettings"));

export const WorkzonesAppSettings = connect((props: IWorkzonesAppSettingsProps) => (
  <LazyLoadComponent>
    <WorkzonesAppSettingsLazy {...props}/>
  </LazyLoadComponent>
));
