import {EWorkZonesManagementRights} from "mhc-server/dist/interfaces";

import {IAppRoute} from "../../application/config/IAppRoute";
import {WorkzonesAppSettings} from "../pages/WorkzonesAppSettings";

import {CIMsSettingsIcon} from "../assets/icons";

export const routeWorkzonesAppSettings: IAppRoute = {
  title: 'Settings',
  subtitle: 'CIM feature setting',
  description: 'Configure the CIM feature of the app',
  icon: <CIMsSettingsIcon/>,
  routePath: '/cims/settings',
  getRoutePath: () => routeWorkzonesAppSettings.routePath,
  menuId: 'cims/settings',
  userHasAllRights: [EWorkZonesManagementRights.WORKZONES_EDIT],
  userHasAnyOfRights: [],
  render: () => <WorkzonesAppSettings/>,
};
