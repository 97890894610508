import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IDeviceManagementMainPageProps} from "./DeviceManagementMainPage";

const DeviceManagementMainPageLazy = lazy(() => import("./DeviceManagementMainPage"));

export const DeviceManagementMainPage = connect((props: IDeviceManagementMainPageProps) => (
  <LazyLoadComponent>
    <DeviceManagementMainPageLazy {...props}/>
  </LazyLoadComponent>
));
