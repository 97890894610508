import {connect} from "react-dynadux";
import {
  lazy,
  LazyLoadComponent,
} from "mhc-ui-components/dist/LazyLoadComponent";
import {IWrongWayResolutionCreateZipTestPageProps} from "./WrongWayResolutionCreateZipTestPage";

const WrongWayResolutionCreateZipTestPageLazy = lazy(() => import("./WrongWayResolutionCreateZipTestPage"));

export const WrongWayResolutionCreateZipTestPage = connect((props: IWrongWayResolutionCreateZipTestPageProps) => (
  <LazyLoadComponent>
    <WrongWayResolutionCreateZipTestPageLazy {...props}/>
  </LazyLoadComponent>
));
