import {IAppRoute} from "../../application/config/IAppRoute";

export const routeDeviceManagerPingPongTestPagePaths: IAppRoute = {
  menuId: '',
  title: 'Ping/pong test page',
  subtitle: 'Device Manager test',
  description: 'Ping/pong test with the Devices Service',
  routePath: '/device-manager/test/ping-pong',
  getRoutePath: () => routeDeviceManagerPingPongTestPagePaths.routePath,
  userHasAllRights: [],
  userHasAnyOfRights: [],
  render: () => null,
};
